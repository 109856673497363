import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { IoHome } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { RiScreenshot2Fill } from "react-icons/ri";
import { LuArrowLeftRight } from "react-icons/lu";
import logoImage from "./../../assets/TssProSEAL.png";
import bgImage from "./../../assets/personal2.png";
import { IoIosDocument } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
type HeaderItem = {
  path?: string;
  label: string;
  icon: IconType;
  count?: number;
  onClickIcons?: (value: any) => void;
};
type Props = {
  children?: React.ReactNode;
  onClick?: (value: any) => void;
  children1?: React.ReactNode;
  className?: string;
};

const MainHeader = ({ children, onClick, children1, className }: Props) => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const HeaderItems: HeaderItem[] = [
    { path: "/", label: "Home", icon: IoHome },
    {
      path: "/support",
      label: "Support",
      icon: MdSupportAgent,
    },
    { path: "/screenshot", label: "ScreenShot", icon: RiScreenshot2Fill },
    {
      path: "/login",
      label: "Login/LogOut",
      icon: LuArrowLeftRight,
      onClickIcons: (e: any) => {
        window.location.reload();
        e.preventDefault();
      },
    },
    {
      path: "/faq",
      label: "FAQ",
      icon: IoIosDocument,
    },
    {
      path: "/customer-review",
      label: "Customer Review",
      icon: CgProfile,
    },
  ];

  return (
    <div>
      <div className="">
        <div className="flex justify-end items-end px-4 py-4">
          <div className="flex gap-3 text-[20px]">
            <span className="font-medium text-gray-600">Font-size</span>
            <button className="color underline">Bigger</button>
            <button className="color underline">Reset</button>
            <button className="color underline">Smaller</button>
          </div>
        </div>
        <div
          className={`text-white bg-[#E0E0E0] flex justify-between gap-4 w-full items-center  `}
        >
          <div className="px-10">
            <h1 className="color font-bold text-[18px]">TssProV2.0</h1>
          </div>
          <div className="flex gap-2">
            <div className="hidden sm:block">
              <ul className="flex gap-6 items-center px-10">
                {HeaderItems?.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.path || ""}
                      onClick={onClick}
                      className={`${location?.pathname === item?.path
                        ? "border-b-[4px] border-[#027bc4]"
                        : "cursor-pointer color"
                        } flex items-center duration-500 transition-all color`}
                    >
                      <div className="flex gap-[5px] items-center my-[10px]">
                        <div>
                          {" "}
                          <item.icon />
                        </div>
                        <h1 className="text-[18px] font-medium">
                          {item?.label}
                        </h1>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sm:hidden py-4">
              <button
                onClick={toggleSidebar}
                className="bg-[#07629D] p-1 text mx-4"
              >
                {isSidebarOpen ? "Close" : "Open"} Menu
              </button>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        {isSidebarOpen && (
          <div
            className={`sidebar sm:hidden transition-all duration-00 ease-in-out`}
          >
            <ul>
              {HeaderItems?.map((item, index) => (
                <li key={index}>
                  <Link to={item.path || ""} onClick={onClick}>
                    <div className="flex text-[#027bc4] items-center gap-4 my-[10px] px-4 py-2 hover:bg-[#E0E0E0]">
                      <div>
                        <item.icon />
                      </div>
                      <h1 className="text-[18px] font-medium">{item?.label}</h1>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="sm:inline hidden">
          <div className="relative">
            <div>
              <img src={bgImage} alt="#" className="w-full h-[300px]" />
            </div>
            <div className="absolute top-[40px] px-10">
              <img src={logoImage} alt="#" className="w-[300px]" />
            </div>
            <h1 className="text-white text-[30px] absolute top-[125px] left-[135px]">
              TssProV2.0
            </h1>
          </div>
        </div>
        <div className="sm:hidden inline">
          <div className="relative ">
            <div>
              <img src={bgImage} alt="#" className="w-full h-[250px]" />
            </div>
            <div className="absolute top-[10px] p-3">
              <img src={logoImage} alt="#" className="w-[200px]" />
            </div>
            <h1 className="text-white text-[20px] absolute top-[85px] left-[80px]">
              TssProV2.0
            </h1>
          </div>
        </div>
        {/* Main Content */}
        <div className="w-full h-full sm:flex items-start justify-start bg-[#F1F1F1] p-4 gap-2">
          <div className=" main-content flex-1 transition-all duration-500 overflow-auto py-2  xl:w-[70%] w-full p-4 ">
            {children}
          </div>
          {children1 && <div className="sm:w-[25%]">{children1}</div>}
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
