import React, { useState } from "react";
import MainHeader from "../headers/Mainheader";

const FandQ = () => {
  const [faqs] = useState([
    {
      question: "Why use a software program for my taxidermy business?",
      answer:
        "If you have been in the taxidermy industry for a while or if you are just starting out, you will need a way to track your customers, jobs, payments and inventory. The old days of tagging and bagging are over. This software will allow you to focus on taxidermy, not all the paperwork. This is the most simple and easy to use taxidermy software on the market. Start using your new software the day you install it. It comes loaded with all your prices and everything you need, right out of the box. If you still have questions after watching the video demo...",
    },
    {
      question:
        "Do I get instant access to a download, or do you have to ship the product to me?",
      answer:
        "You will get instant access to download the product after you purchase it. Save the file to a disc or hard drive and keep it for a backup. If needed, you can always come back to this site to download the new version. Our software is READY TO USE OUT OF THE BOX! It comes preloaded with all Species, Prices, and Mount Types. This is going to save you a ton of time! Just install and start taking in customers!",
    },
    {
      question:
        "Is Taxidermy Software System Pro a web based product or does it install on my computer?",
      answer:
        "Taxidermy Software System Pro installs on your computer and does not need constant internet connection to work. As you know most taxidermists don't have an internet connection in their shop. Another reason is for security. We don't want all of your customers' information on a database stored in the web...",
    },
    {
      question: "Do I get free version updates or do I have to pay for them?",
      answer:
        "All Updates are free, and you should check our site often to make sure you are running the latest version. We continue to get feedback, and we add new features to help make the Taxidermy Software System that much better. We want perfection and happy customers!",
    },
    {
      question: "What operating system will this software work on?",
      answer:
        "Any Windows-based system, including Windows XP, Windows Vista, Windows 7, Windows 8, 8.1, and 10. The software will not work on a Mac, however, we are working on a version for the Mac system.",
    },
    {
      question:
        "What if my computer were to crash? Can I install the software on another computer without losing any data?",
      answer:
        "Yes, when you purchase the program, we keep your email and name on file. As far as restoring your information, there is a menu tab called Backup Database. When you click on that button, it makes a copy of your database. When you’re ready to restore your database, all you need to do is click on the Restore Database tab under the Help menu button and you’re all set. We recommend that you backup your database once a week or once a month and save it to a flash drive or you can use another program to auto backup your database files, such as Google Drive.",
    },
    {
      question:
        "What if I charge per foot or per inch; will the software allow me to do that?",
      answer:
        "Yes. When you make a new service such as Bass Mount, in the description you could put something like 'Per Foot.' Now when you go to add this service to your job and the bass is 2 feet, you would simply change the qty to 2. Now you are charging by the foot.",
    },
    {
      question:
        "Can I export my customer information to Excel format, and can I importdata into the database using an Excel forma",
      answer:
        "Yes. Under the Help tab in the main menu bar, there are two buttons called Export Customer Date To Excel and Import Customer Data From Excel. Now you can transfer your customers from your old system and get this new Taxidermy Software up and running in no time!",
    },
    {
      question: "​Why Should I Use Taxidermy Software System Pro?",
      answer:
        "​Taxidermy Software System Pro is a simple, easy-to-use program that ANYONE can work with. Whether you are a small shop with a few customers or a large shop with hundreds of customers, this software will work for your business. You will have the power to have a fast customer check-in, even if they bring you more than one animal you will be able to get them in and out in minutes. Keep track of taxes, profits, unfinished jobs, active jobs, parts inventory control, and invoicing and change your company information, such as the logo, business name, address, phone, etc. Save time by entering your shop expenses in one easy location and printing reports based on date ranges, a great feature come tax time. Don't forget about our Game Tracking feature. It will track the customer’s hunting license, species, date brought in, state, and harvest tag or ser number, and it will print that in a report based on the date brought in. As far as support, you can call us direct anytime day or night and we will answer the phone. If for whatever reason we can't get our hands on a phone, you will get a call back in less than a few hours. Customer support is how you get and keep happy customers! As a side note when buying software for a business you have to look at the long term effects. Such as 'If I buy a software program from a company that charges a one time fee, how will they continue to support that product?' or 'How much do they charge me if I need to change my address or make any small modification?' Some of the other taxidermy software companies actually charge you over $200.00 if you should ever move and have to change your address! Do your research and know that our price is the lowest and our support is like life insurance for your business!",
    },
  ]);

  return (
    <div>
      <MainHeader
        children={
          <div className="sm:px-[80px] w-full px-8">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-500 text-center">
              TAXIDERMY SOFTWARE SYSTEM PRO "FAQS"
            </h1>
            <div className="flex flex-col gap-5">
              {faqs?.map((items) => {
                return (
                  <div className="bg-white  p-6  shadow rounded-2xl w-full">
                    <h1 className="text-lg font-bold mb-2 color">
                      {" "}
                      {items.question}
                    </h1>
                    <p className="textColor font-medium mb-4">{items.answer}</p>
                  </div>
                );
              })}
            </div>
            <div className="bg-white p-5 rounded-2xl shadow mt-4 ">
              <i className="textColor font-medium px-4 ">
                {" "}
                As a personal note, I would like to thank all of our customers
                and a very special one in Gilbert, AZ for all the feedback and
                suggestions that we have incorporated in our software. I would
                also like to thank Mr. Bill for his master programming skills
                and his good looks!
              </i>
              <div className=" font-semibold textColor p-2 mb-1 ">
                <p>
                  Thank you, <br />
                  Ryan St. Louis <br />
                  CEO and Software Developer
                </p>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FandQ;
