import React, { ReactNode } from "react";
type Props = {
  title: ReactNode;
  time: ReactNode;
  image: string;
  onClick: () => void;
};
const SupportCard = ({ title, time, image, onClick }: Props) => {
  return (
    <div>
      <div className="flex bg-white shadow rounded-2xl p-4 gap-3">
        <div className="">
          <img
            src={image}
            alt=" "
            className="w-[120px] h-[120px] object-cover"
          />
        </div>
        <div className="flex flex-col gap-1 items-start justify-center">
          <div className="text-gray-800 font-semibold text-[22px] truncate">
            {title}
          </div>
          <div className="text-gray-600 font-medium text-[18px]">{time}</div>
          <button
            className="bg-[#027bc4] w-fit px-5 py-[8px] text-md font-medium rounded-md shadow-lg text-white hover:bg-blue-500 hover:text-white"
            onClick={onClick}
          >
            Book This
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportCard;
