import { TextField } from "@mui/material";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import MainHeader from "../headers/Mainheader";
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState<boolean>(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // handle login logic here
  };
  return (
    <MainHeader>
      <div className="flex items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full border border-gray-300 p-5 rounded-md">
          <div>
            {/* <img
    className="mx-auto h-12 w-auto"
    src="https://tailwindui.com/logos/workflow-mark-on-white.svg"
    alt="Workflow"
  /> */}
            <h2 className="mt-6  text-md font-medium text-gray-700">
              Please enter the email address for your account. A verification code
              will be sent to you. Once you have received the verification code,
              you will be able to choose a new password for your account.
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                {" "}
                <TextField
                  sx={{ backgroundColor: "white", width: "100%" }}
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                  label="Email"
                  required={true}
                  id="outlined-basic"
                  placeholder="Email"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-gray-500">
                Capatcha <span className="text-red-500"> * </span>
              </div>
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={() => {
                  setVerified(true);
                }}
              />
            </div>
            <div className="gap-3 flex items-center">
              <div className="flex justify-center">
                <button
                  disabled={!verified}
                  className="text-white text-[18px] font-medium xl:text-[20px] bg-[#027BC4] px-10 py-3 rounded-lg "
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainHeader>
  );
};

export default ResetPassword;
