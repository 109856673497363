import React from "react";
import MainHeader from "../headers/Mainheader";
import profileImage from "./../../assets/download.png";
import profileImage2 from "./../../assets/images.jpg";
import { useNavigate } from "react-router-dom";
import TextareaComment from "../../components/commentBox/CommentBox";
import ADs from "../add/Ads";
type Props = {};

const HomeCardData = [
  {
    title: "TssProV2.0 Monthly Subscription",
    price: "500",
    description: <></>,
  },
  {
    title: "TssProV2.0 Monthly Subscription",
    price: "500",
    description: <></>,
  },
];
const commentData = [
  {
    img: profileImage2,
    username: "John Willian",
    comments:
      "It is a long established fact that a reader will be distracted by the readable content of a page. ",
  },
  {
    img: profileImage,
    username: "John Smith",
    comments:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 ",
  },
  {
    img: profileImage2,
    username: "Simona Disa ",
    comments:
      "A Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur ",
  },
  {
    img: profileImage,
    username: "Natalie Smith",
    comments:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32",
  },
];
const Home = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <MainHeader
        children={
          <div className=" ">
            <div className="">
              <div className="flex items-center  gap-2">
                <div className="border-b border-gray-300 pb-1 w-fit px-1 text-gray-500 font-semibold text-[25px]">
                  Latest Version
                </div>
                <div>
                  <a
                    href="/Latest Version 2.0.4.7"
                    className="color font-semibold text-[20px]"
                  >
                    Latest Version 2.0.4.7
                  </a>
                </div>
              </div>
              {/* <div>
            <img src={addImage} alt="" />
          </div> */}
              <div className=" py-4 bg-white rounded-md shadow">
                <div>
                  <ul className="list-disc textColor w-full sm:px-10 px-8 text-[14px] sm:text-[16px]">
                    <li>Taxidermy software for your P.C.</li>
                    <li>Our software installs on your computer.</li>
                    <li>Built-in the USA 100%.</li>
                    <li>
                      Customer support by the developer himself, via cell or
                      remote assistance.
                    </li>
                    <li>
                      Perfect for the full-time, professional taxidermist
                      looking for dedicated software in the taxidermy industry.
                    </li>
                    <li>
                      Finally! Taxidermy software system and support; at an
                      exceptional price.
                    </li>
                  </ul>
                </div>
                <div className="py-4 sm:px-5 px-2">
                  <ul className="list sm:text-[16px] text-[14px] textColor px-10">
                    <li>
                      The software will be ready for download instantly after
                      payment.
                    </li>
                    <li>
                      We include with your purchase a free one-page "Landing
                      Page" for your business.
                    </li>
                    <li>
                      The free one-page article will drive customers to you and
                      link you to our site.
                    </li>
                    <li>The software includes: Unlimited customers</li>
                    <li>
                      Unlimited jobs with no need to pay for anything extra!
                    </li>
                    <li>Free software updates</li>
                    <li>Free unlimited tech support 24/7</li>
                    <li>
                      Free unlimited software upgrades for the life of your
                      subscription.
                    </li>
                    <li>Free Live Person to Person Support</li>
                    <li>Free Remote Computer Access Support</li>
                    <li>
                      Promotion products that show your customers that you are a
                      2.0VPRO shop!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-2  gap-4 my-4 ">
              {HomeCardData?.map((item, index) => {
                return (
                  <div
                    className={`bg-white  rounded-2xl p-6  gap-3 border border-gray-200 shadow`}
                    key={index}
                  >
                    <h2 className="text-[24px] font-medium">{item?.title}</h2>
                    <div className="font-medium text-[16px] my-2 flex gap-1">
                      <span className="theneColor">$</span>
                      <span>{item?.price}</span>
                    </div>
                    {item?.description && <div>{item?.description}</div>}
                    <button className="bg-[#027bc4] w-fit px-8 py-[8px] text-md font-medium rounded-md shadow-lg text-white hover:bg-blue-500 hover:text-white">
                      <a href="https://www.paypal.com/cgi-bin/webscr">
                        <span className="">Buy Now</span>
                      </a>
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="">
              <div
                className={`bg-white  rounded-lg p-6  gap-3 border border-gray-200 shadow`}
              >
                <h2 className="text-[24px] font-medium">TssProV2.0 Buy Now</h2>
                <div className="font-medium text-[16px] my-2 flex gap-1">
                  <span className="theneColor">$</span>
                  <span>599.95</span>
                </div>
                <div className=" py-1">
                  <div>
                    <ul className="list-disc sm:text-[16px] text-[14px] textColor w-full sm:px-10 px-5">
                      <li>
                        Purchase the software in full. After that, you own the
                        software with a lifetime membership. Perfect for t he
                        full-time professional taxidermist who needs a taxidermy
                        business software solution at an exceptional price.
                      </li>
                      <li>
                        Instant Download, Our taxidermy software installs on
                        your Windows PC.
                        <span className="text-gray-900 font-medium">
                          {" "}
                          No constant internet connection is needed!
                        </span>
                        No need to worry about your customer's info on a server.
                        If it's on the web it can be hacked! Just ask Facebook,
                        this is why our
                        <span className="text-gray-900 font-medium">
                          software installs on your PC, not on the web!
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="py-2 px-5">
                    <ul className="list  text-[16px] textColor px-4">
                      <li>
                        A free one-page article that will drive customers to
                        your business and link to our site.
                      </li>
                      <li>Unlimited Customers</li>
                      <li>Unlimited Jobs</li>
                      <li>Free Catalog Imports</li>
                      <li>Free Software updates</li>
                      <li>Free Unlimited Tech support 24/7</li>

                      <li>
                        Free unlimited software upgrades for the life of your
                        subscription.
                      </li>
                      <li>Free Live Person to Person Support</li>
                      <li>Free Remote Computer Access Support</li>
                      <li>
                        Promotion products that show your customers that you are
                        a 2.0VPRO shop!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow p-6  rounded-md my-4 sm:text-[16px] text-[14px] flex flex-col gap-4">
                <div className=" ">
                  <span className="color font-medium"> NOTE: </span>
                  All prices are locked in and will never increase! There are no
                  contracts; you can cancel at any time.With any of the
                  subscription plans, you get unlimited tech support and catalog
                  imports.To cancel your subscription at any time, send us a
                  request or cancel your subscription by using your Paypal
                  account.
                </div>
                <div className="">
                  <span className="color font-medium">
                    {" "}
                    Still, Have Questions?{" "}
                  </span>
                  We want you to know how to use the software and see just what
                  it can do for your business. We strongly suggest that you join
                  in on a live demo while we answer questions, walk you through
                  step by step: Adding a new customer, adding a new job,
                  printing an invoice, accepting payments, job management, and
                  reporting. The demo will last about 20 minutes, but it will be
                  well worth your time. Please sign up to get your live demo
                  today!
                </div>
                <button
                  className="color underline items-start flex flex-col"
                  onClick={() => navigate("/screenshot")}
                >
                  view some screenshots{" "}
                </button>
              </div>
            </div>
          </div>
        }
        children1={
          <div className="flex flex-col sm:gap-10 gap-4 sm:mt-12 mt-3">
            <div>
              <ADs />
            </div>
            <div className="flex flex-col h-full bg-white shadow rounded-2xl">
              <div
                className="flex-grow-0 p-2 bg-[#0C68A2] text-white"
                style={{
                  borderTopRightRadius: "6px",
                  borderTopLeftRadius: "6px",
                }}
              >
                <p>Comments</p>
              </div>
              <div className="flex-grow bg-white overflow-auto scrollbar p-5 h-[500px]">
                <div className="flex flex-col gap-5">
                  {commentData?.map((comment) => {
                    return (
                      <div className="shadow border border-gray-200 p-4 rounded-2xl">
                        <div className="font-medium flex gap-2 items-center">
                          <img
                            src={comment?.img}
                            alt=""
                            className="w-[40px] h-[40px] rounded-full"
                          />
                          <span className="flex-1 truncate">
                            {comment?.username}
                          </span>
                        </div>
                        <div className="text-[12px] font-normal">
                          {comment?.comments}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex-grow-0 my-3 bg-white px-4">
                <TextareaComment />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Home;
