import React, { useState } from "react";

const TextareaComment = () => {
  const [italic, setItalic] = useState(false);
  const [fontWeight, setFontWeight] = useState("normal");

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxWidth: "400px" }}
    >
      <textarea
        placeholder="Type comment here…"
        className="outline-none"
        style={{
          width: "100%",
          minHeight: "100px",
          backgroundColor: "#F1F1F1",
          fontWeight,
          fontStyle: italic ? "italic" : "normal",
          resize: "none",
          padding: "5px",
          border: "1px solid #ccc",
          borderTopRightRadius: "6px",
          borderTopLeftRadius: "6px"
        }}
      />
      <div className="bg-gray-300 mt-0 flex items-center justify-between px-0">
        <button
          style={{
            marginRight: "10px",
            fontWeight: fontWeight === "bold" ? "bold" : "normal",
            textDecoration: italic ? "italic" : "none",
          }}
          className="border-r-[1px] border-gray-500 px-2"
          onClick={() =>
            setFontWeight(fontWeight === "bold" ? "normal" : "bold")
          }
        >
          Bold
        </button>
        <button
          className="border-r-[1px] border-gray-500 px-2"
          style={{
            fontWeight: "normal",
            textDecoration: italic ? "italic" : "none",
          }}
          onClick={() => setItalic(!italic)}
        >
          Italic
        </button>
        <button
          className="text-[10px]"
          style={{
            marginLeft: "auto",
            padding: "2px 10px",
            borderRadius: "3px",
            border: "none",
            backgroundColor: "#007bff",
            color: "#fff",
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};
export default TextareaComment;
