import React from "react";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import screenShotImage1 from "./../../assets/screenshot01.png";
import screenShotImage2 from "./../../assets/screenshot02.jpg";
import screenShotImage3 from "./../../assets/screenshot03.png";
import screenShotImage4 from "./../../assets/screenshot04.png";
import MainHeader from "../headers/Mainheader";

const Screenshot = () => {
  const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="slick-custom-arrow slick-next" onClick={onClick}>
        <IoIosArrowDropright />
      </div>
    );
  };

  const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="slick-custom-arrow slick-prev" onClick={onClick}>
        <IoIosArrowDropleft />
      </div>
    );
  };

  const ScreenshotImage = [
    screenShotImage1,
    screenShotImage2,
    screenShotImage3,
    screenShotImage4,
  ];

  var settings = {
    customPaging: function (index: any) {
      return (
        <div>
          <div className="border-2  border-pink-500 w-full flex gap-3 h-full">
            <img src={ScreenshotImage[index]} alt={""} />
          </div>
        </div>
      );
    },
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // dots: true,
    // dotsClass: "slick-dots slick-thumb border border-blue-500 ",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <MainHeader
        children={
          <div className="p-5 flex flex-col gap-5  h-full">
            <div className="text-[32px] font-bold text-gray-700">
              Taxidermy Software System ProV2.0
            </div>
            <div className="md:inline-block hidden bg-blue-100 p-6 rounded-2xl">
              <Slider {...settings} className="w-full">
                {ScreenshotImage.map((image, index) => (
                  <div
                    key={index}
                    className="w-full h-fit object-cover cursor-pointer"
                  >
                    <img
                      src={image}
                      alt=""
                      className="w-full h-full md:h-screen"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="flex flex-col gap-8 md:hidden">
              {ScreenshotImage.map((image, index) => (
                <div
                  key={index}
                  className="w-full h-fit border border-gray-300 object-cover cursor-pointer"
                >
                  <img src={image} alt="" className="w-full h-full" />
                </div>
              ))}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Screenshot;
