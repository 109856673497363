import React from "react";
import image from "./../../assets/458164967327f5d45c5773c1460ca68f.jpg";

const ADs = () => {
    return (
        <div className="flex flex-col justify-center items-center rounded-2xl p-4 border border-gray-300 ">
            <div className="text-center">
                <img src={image} alt="Advertisement" className="w-full h-[250px]" />
                <p className="text-sm md:text-base lg:text-lg mt-2">
                    Texitary-Software
                </p>
            </div>
        </div>
    );
};

export default ADs;
