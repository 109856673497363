import React from "react";
import { BrowserRouter } from "react-router-dom";
import PageRoute from "./pageRoute/PageRoute";
// import store from "./slice/store";

type Props = {};

const App = (props: Props) => {
  return (
    <div>
      <BrowserRouter>
        {/* <Toaster /> */}
        <PageRoute />
      </BrowserRouter>
    </div>
  );
};

export default App;