import React, { useState } from "react";
import MainHeader from "../headers/Mainheader";

interface ReviewProps {
  review: {
    name: string;
    comment: string;
    // location: string;
  };
}

const Review: React.FC<ReviewProps> = ({ review }) => {
  return (
    <div className="bg-white shadow rounded-md p-6 mb-4  mx-[10%] border border-gray-200">
      <div className="text-2xl  font-medium mb-2 text-blue-500">
        {review.name}
      </div>
      <p className="textColor mb-4">{review?.comment}</p>
    </div>
  );
};

const CustomerReviewPage = () => {
  const [reviews] = useState([
    {
      name: " - Steve Pucko owner AJ's Taxidermy Phillipston Mass",
      comment:
        "After this past tax season and many hours speaking with my accountant and looking for receipts and invoices, I knew it was time to invest in a database for my taxidermy business. My needs are very specific, and I'm not one to buy into a lot of needless bells and whistles. Taxidermy Software System Pro does just that and then some. Ryan's customer support is second to none.",
    },
    {
      name: " - Dale Kuhlman ,AZ Wildlife Creations",
      comment:
        "I've been a taxidermist for over 40 years, and tracking my business from expenditures to profits is important to me. Taxidermy Software System Pro allows me to administer simple changes at no cost. Ryan, the owner, is a standup individual and a pleasure to work with. Give his software a chance - you'll like what you see!",
    },
    {
      name: "Matthew Taylor, TX",
      comment:
        "I was using another Taxidermy Software Program, and it was so complicated that I couldn't even find out how to track my profit. That's when I found Taxidermy Software System Pro. Ryan's video demo made it a no-brainer. His customer support is great! I would recommend his software to anyone in the Taxidermy business.",
    },
    {
      name: " ​- Danny carpenter  Owner of Mounted in Mcgregor  Mcgregor Texas",
      comment:
        "Just wanted to thank you not only for the software program but also for the support you have given me after the purchase of the program. Taxidermy Software System Pro, plus Ryan's experience in taxidermy, gave me only what I needed to conduct my taxidermy business. Now for the thank you! Anyone thinking about getting this software let me tell you about the support that I received from Ryan. Thank you again, Ryan!",
    },
  ]);

  return (
    <div>
      <MainHeader>
        <div className="">
          <h1 className="text-2xl sm:text-[30px] font-bold mb-4 text-gray-500 flex justify-center sm:py-5 py-2">
            HERE IS WHAT OUR CUSTOMERS THINK...
          </h1>
          <div className="review-list">
            {reviews?.map((review, index) => (
              <Review key={index} review={review} />
            ))}
          </div>
        </div>
      </MainHeader>
    </div>
  );
};

export default CustomerReviewPage;
