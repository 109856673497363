import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import MainHeader from "../headers/Mainheader";
import ADs from "../add/Ads";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // handle login logic here
  };

  return (
    <div>
      <MainHeader
        children={
          <div className="sm:flex gap-4 items-start justify-center h-full bg-gray-50 py-5 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full border border-gray-300 p-5 rounded-2xl bg-white shadow">
              <div>
                {/* <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/logos/workflow-mark-on-white.svg"
            alt="Workflow"
          /> */}
                <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                  Sign in to your account
                </h2>
              </div>
              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                <input type="hidden" name="remember" value="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    {" "}
                    <TextField
                      sx={{ backgroundColor: "#F9FAFB", width: "100%" }}
                      value={username}
                      onChange={(e: any) => {
                        setUsername(e.target.value);
                      }}
                      label="User Name"
                      id="outlined-basic"
                      placeholder="User Name"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    {" "}
                    <TextField
                      sx={{ backgroundColor: "#F9FAFB", width: "100%" }}
                      value={password}
                      onChange={(e: any) => {
                        setPassword(e.target.value);
                      }}
                      label="Password"
                      id="outlined-basic"
                      placeholder="Password"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="gap-3 flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 accent-blue-600 "
                  />{" "}
                  <span className="text-[18px] xl:text-[20px] font-medium">
                    Remember me
                  </span>
                </div>
                <div className="flex justify-center">
                  <button className="text-white text-[16px] font-medium xl:text-[20px] bg-[#027bc4] px-10 py-3 rounded-lg hover:bg-blue-500  ">
                    Login
                  </button>
                </div>
                <div className="flex flex-col gap-1 items-start">
                  <div className="">
                    <span
                      className="text-[18px] color xl:text-[20px] underline font-medium"
                      onClick={() => {
                        navigate("/reset-password");
                      }}
                    >
                      Forgot Your Password ?
                    </span>
                  </div>
                  <div>
                    <span className="text-[18px] xl:text-[20px] color underline font-medium">
                      Forgot Your User Name ?
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <div >
                <ADs />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Login;
