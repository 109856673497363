import React from "react";
import softwareDemoImage from "./../../assets/458164967327f5d45c5773c1460ca68f.jpg";
import securityImage from "./../../assets/securityimage.jpg";
import SupportCard from "./component/SupportCard";
import MainHeader from "../headers/Mainheader";
import ADs from "../add/Ads";

const supportData = [
  {
    image: softwareDemoImage,
    title: "Software Support",
    time: "15 min",
  },
  {
    image: securityImage,
    title: "Remote Support",
    time: "45 mins",
  },
  {
    image: securityImage,
    title: "Remote Support",
    time: "15 min",
  },
  {
    image: softwareDemoImage,
    title: "Custom Install",
    time: "15 min",
  },
  {
    image: softwareDemoImage,
    title: "Remote PC Clean and Fix",
    time: "15 min",
  },
];
const Support = () => {
  return (
    <div>
      <MainHeader
        children={
          <div className="p-2 flex flex-col gap-3">
            <div className="sm:flex items-center  gap-2">
              <div className="border-b border-gray-300 pb-1 w-fit px-1 text-gray-500 font-semibold text-[25px]">
                Latest Version
              </div>
              <div>
                <a
                  href="/Latest Version 2.0.4.7"
                  className="color font-semibold text-[20px]"
                >
                  Latest Version 2.0.4.7
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-gray-700 text-xl">
                Call us at 863-838-6779 or select an option below...
              </div>
              <div className="text-gray-500 font-bold text-[30px]">
                Welcome !
              </div>

              <div className="grid sm:grid-cols-2 gap-5">
                {supportData?.map((el) => {
                  return (
                    <>
                      <SupportCard
                        title={el?.title}
                        time={el?.time}
                        onClick={() => { }}
                        image={el?.image}
                      />
                    </>
                  );
                })}
              </div>
              {/* <div className="grid  md:grid-cols-2 xl:grid-cols-3 gap-5">
                <SupportCard
                  title={"Software Support"}
                  time={"15 min"}
                  onClick={() => { }}
                  image={softwareDemoImage}
                />
                <SupportCard
                  title={"Remote Support"}
                  time={<>15 min</>}
                  onClick={() => { }}
                  image={securityImage}
                />
                <SupportCard
                  title={<>Remote Support (Paid Customer)</>}
                  time={"15 min"}
                  onClick={() => { }}
                  image={securityImage}
                />
                <SupportCard
                  title={"Custom Install"}
                  time={"15 min"}
                  onClick={() => { }}
                  image={softwareDemoImage}
                />
                <SupportCard
                  title={"Remote PC Clean and Fix"}
                  time={
                    <>
                      <span className="text-blue-700">149.95 USD</span>| 1 hr 45
                      mins
                    </>
                  }
                  onClick={() => { }}
                  image={softwareDemoImage}
                />
              </div> */}
            </div>
          </div>
        }
        children1={
          <div>
            <ADs />
          </div>
        }
      ></MainHeader>
    </div>
  );
};

export default Support;
