import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../screens/home/Home";
import Supports from "../screens/supports/Supports";
import Screenshots from "../screens/screenshots/Screenshots";
import FandQ from "../screens/FAQ/Faq";
import CustomerReviewPage from "../screens/reviewer/Reviewer";
import Login from "../screens/auth/Login";
import ResetPassword from "../screens/auth/ForgotPassword";

type Props = {};

const PageRoute = (props: Props) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Supports />} />
        <Route path="/screenshot" element={<Screenshots />} />
        <Route path="/faq" element={<FandQ />} />
        <Route path="/customer-review" element={<CustomerReviewPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </div>
  );
};

export default PageRoute;
